import "98.css";

let elDict = [{
    "id": "draggable",
    "damping": 0.95,
    "auto": false
}, {
    "id": "infinitely-bouncing",
    "damping": 1,
    "auto": true
}];

elDict.forEach((e) => {
    let el = document.getElementById(e.id);
    if (el != undefined) {
        document.addEventListener("DOMContentLoaded", () => animate(el, e.damping, e.auto));
    }
});

document.addEventListener("DOMContentLoaded", setClickEventOnImage());

function setClickEventOnImage() {
    const images = document.querySelectorAll('.collage img');

    images.forEach(image => {
        image.addEventListener('click', () => {
            window.open(image.src, '_blank');
        });
    });
}

// lower damping = slower stop
function animate(el, damping, bounceAutomatically) {
    let isDragging = false;
    let deltaX = 0, deltaY = 0, offsetX = 0, offsetY = 0;
    let vx = 0, vy = 0;
    let currentX = el.offsetLeft;
    let currentY = el.offsetTop;

    if (bounceAutomatically) {
        animateBounce(currentX, currentY, Math.random() * 5, Math.random() * 5);
    } else {
        el.addEventListener("dragstart", startDragging);
        el.addEventListener("drag", drag);
        el.addEventListener("dragend", stopDragging);
    }

    window.addEventListener('resize', () => {
        if (!isDragging) {
            // Adjust position to stay within the window bounds after resizing
            let rect = el.getBoundingClientRect();
            let winW = window.innerWidth;
            let winH = window.innerHeight;

            let newX = Math.max(0, Math.min(winW - rect.width, currentX));
            let newY = Math.max(0, Math.min(winH - rect.height, currentY));

            currentX = newX;
            currentY = newY;

            el.style.left = currentX + 'px';
            el.style.top = currentY + 'px';
        }
    });

    function startDragging(e) {
        isDragging = true;

        // Create an invisible/empty image for the drag image
        var dragIcon = document.createElement('img');
        dragIcon.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
        dragIcon.width = 1;
        e.dataTransfer.setDragImage(dragIcon, 0, 0);

        offsetX = e.clientX - el.getBoundingClientRect().left;
        offsetY = e.clientY - el.getBoundingClientRect().top;
        deltaX = e.clientX;
        deltaY = e.clientY;
    }

    function drag(e) {
        if (!isDragging) return;

        currentX = e.clientX - offsetX;
        currentY = e.clientY - offsetY;

        el.style.left = currentX + "px";
        el.style.top = currentY + "px";
    }

    function stopDragging(e) {
        if (!isDragging) return;
        isDragging = false;

        deltaX = e.clientX - deltaX;
        deltaY = e.clientY - deltaY;

        vx = deltaX;
        vy = deltaY;

        animateBounce(currentX, currentY, vx, vy);
    }

    function animateBounce(x, y, vx, vy) {
        function frame() {
            let rect = el.getBoundingClientRect();
            let winW = window.innerWidth;
            let winH = window.innerHeight;

            x += vx;
            y += vy;

            if (x < 0 || x + rect.width > winW) {
                vx = -vx;
                x = Math.max(0, Math.min(winW - rect.width, x));
            }

            if (y < 0 || y + rect.height > winH) {
                vy = -vy;
                y = Math.max(0, Math.min(winH - rect.height, y));
            }

            currentX = x;
            currentY = y;

            el.style.left = currentX + 'px';
            el.style.top = currentY + 'px';

            vx *= damping;
            vy *= damping;

            if (Math.abs(vx) > 0.5 || Math.abs(vy) > 0.5) {
                requestAnimationFrame(frame);
            }
        }

        frame();
    }
}
